<script setup lang="ts">
import { formatPrice } from '@glow/shared/utils/format';
import { formatServiceCount } from '@/features/services';
import { type FragmentType, graphql } from '@/gql';
import { useRoutes } from '@glow/shared/lib/routes';

const masterFragment = graphql(/* GraphQL */ `
  fragment CartServiceMasterGroup_MasterFragment on Master {
    id
    slug
    fullName
    avatarUrl
    workPlaces {
      id
      cartServices {
        id
        partnerService {
          id
          price
          duration
          service {
            id
            slug
            name
          }
        }
      }
    }
  }
`);

const props = defineProps<{
  master: FragmentType<typeof masterFragment>;
}>();

const master = useFragmentData(masterFragment, () => props.master);
const cartServices = computed(() =>
  master.value.workPlaces.flatMap((workPlace) => workPlace.cartServices)
);

const { getUrl } = useRoutes();
const { staticUrl } = useRuntimeConfig().public;

const link = computed(() =>
  getUrl('partnerDetails', {
    type: 'master',
    city: 'moscow', // FIXME
    partner: master.value.slug,
  })
);

function getServiceLink(service: (typeof cartServices)['value'][number]) {
  return getUrl('partnerService', {
    partnerType: 'master',
    city: 'moscow', // FIXME
    partnerSlug: master.value.slug,
    service: service.partnerService.service.slug,
  });
}
</script>

<template>
  <div class="group">
    <NuxtLink class="partner" :to="link">
      <AppAvatar class="partner__avatar" :src="master.avatarUrl" />
      <div>
        <div class="partner__name">{{ master.fullName }}</div>
        <div class="partner__service-count">
          {{ formatServiceCount(cartServices.length) }}
        </div>
      </div>
    </NuxtLink>
    <hr class="separator" />
    <NuxtLink
      v-for="service in cartServices"
      :key="service.id"
      :to="getServiceLink(service)"
      class="service"
    >
      <NuxtImg
        :src="`${staticUrl}/images/services/${service.id}.jpg`"
        class="rounded-sm"
        width="44"
        height="44"
        fit="cover"
      />
      <div>
        <div class="font-bold">{{ service.partnerService.service.name }}</div>
        {{ formatPrice(service.partnerService.price) }}
      </div>
    </NuxtLink>
  </div>
</template>

<style scoped lang="scss">
.group {
  background: var(--app-color-white);
  padding: 16px;
  display: flex;
  gap: var(--app-spacing-3);
  flex-direction: column;
  border-radius: 16px;
  border: 1px solid #f9f9f9;
}

.partner {
  display: flex;
  align-items: center;
  gap: 12px;

  &__avatar {
    width: 44px;
    height: 44px;
  }

  &__name {
    color: var(--app-color-black);
    font-size: 16px;
    font-weight: 500;
  }

  &__service-count {
    color: var(--app-color-black-secondary);
    font-size: 14px;
    font-weight: 400;
  }
}

.service {
  color: var(--app-color-black);
  display: flex;
  align-items: center;
  gap: 12px;
  font-size: 14px;
}

.separator {
  width: 100%;
  border: none;
  border-top: 1px solid #ececec;
}
</style>
