<script setup lang="ts">
import { useRoutes } from '@glow/shared/lib/routes';
import { graphql } from '@/gql';
import CartServiceMasterGroup from './CartServiceMasterGroup.vue';
import { useQuery } from '@urql/vue';

const { getUrl } = useRoutes();
const cartUrl = getUrl('cart');
const { data: queryData } = useQuery({
  query: graphql(/* GraphQL */ `
    query CartBlock_CartMastersQuery {
      cartMasters {
        id
        ...CartServiceMasterGroup_MasterFragment
      }
    }
  `),
});
const { staticUrl } = useRuntimeConfig().public;
const cartMasters = toRef(() => queryData.value?.cartMasters ?? []);
</script>

<template>
  <div class="cart page-card">
    <NuxtLink :to="cartUrl" class="cart__title text-dark">
      Корзина
      <span class="text-primary">{{ cartMasters.length }}</span>
    </NuxtLink>
    <CartServiceMasterGroup
      v-for="master in cartMasters"
      :key="master.id"
      :master="master"
    />
    <div v-if="!cartMasters.length" class="cart__no-services">
      <NuxtImg
        width="220"
        height="220"
        :src="`${staticUrl}/images/icons/appointment.png`"
      />
      <div>Пока что вы не добавили никаких услуг в корзину</div>
    </div>
    <div class="cart__link-button">
      <AppButton fluid :to="cartUrl">Перейти в корзину</AppButton>
    </div>
  </div>
</template>

<style scoped lang="scss">
.cart {
  padding: var(--app-spacing-6) var(--app-spacing-2);
  display: flex;
  flex-direction: column;
  gap: 20px;

  &__title {
    font-size: 24px;
    font-weight: 700;
  }

  &__no-services {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: var(--app-spacing-3);
    text-align: center;
  }

  &__title,
  &__no-services,
  &__link-button {
    padding: 0 var(--app-spacing-3);
  }
}
</style>
